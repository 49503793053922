import React from "react";
import { Header } from "../Layout/Header";
import {ServicesContainer} from "./ServicesContainer";
import {ServicesBanner} from "./ServicesBanner";

const Services = () => {
  return (
    <>
      <Header className="header_dark" />
      <ServicesBanner />
      <ServicesContainer/>
    </>
  );
};

export default Services;
