import React from "react";
import { Header } from "../Layout/Header";
import {PortfolioBanner} from "./PortfolioBanner";
import {PortfolioDetail} from "./PortfolioDetail";
const PortfolioMain = () => {
  return (
    <>
        <Header className="header_dark" />
      <PortfolioBanner />
      <PortfolioDetail />
    </>
  );
};

export default PortfolioMain;
