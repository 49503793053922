import React from "react";
import "../../scss/layouts/_hero.scss";

export const ServicesBanner: React.FC<any> = () => (
  <>
    <section className="hero-section header_dark">
      <div className="container">
        <div className="row">
          <div className="col-sm-6 col-lg-5">
            <div className="hero__content">
              <div className="hero__content__verticall_lebel">
                <div className="text-rotate">
                  <span>
                    <a href="">Services we offer</a>
                  </span>
                </div>
              </div>
              <div className="hero__content__desc">
                <h3>Services we offer</h3>
                <p>Software services for companies of all sizes</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
);
