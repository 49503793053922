import React from "react";
import { Header } from "../Layout/Header";
import { AboutMain } from "./AboutMain";
import { AboutBanner } from "./AboutBanner";
import {Services} from "../Main/Services";
import {AboutServices} from "./AboutServices";

const AboutUs = () => {
  return (
    <>
      <Header className="header_dark" />
      <AboutBanner />
        <AboutServices/>
    </>
  );
};

export default AboutUs;
