import React from "react";
import { Header } from "./Header";

export const NotFound: React.FC<any> = () => {
  return (
    <>
      <Header />
      <div>
        <h1>404 - No Page Found</h1>
      </div>
    </>
  );
};
