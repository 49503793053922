import React from "react";
import "../../scss/layouts/_services.scss";
import Images from "../../assets";

export const Services: React.FC<any> = () => (
  <>
    <section className="services-section" id='what-we-do'>
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-sm-6">
            <div className="services__card">
              <div className="services__card__icon">
                <img className="icon-dark" src={Images.icon1} alt="icon" />
                <img
                  className="icon-white"
                  src={Images.icon1White}
                  alt="icon-1-white"
                />
              </div>
              <div className="services__card__title">
                <h3>Web Development</h3>
              </div>
              <div className="services__card__desc">
                <p>
                  It&apos;s just one of our specialties - that means we&apos;re the best we possibly can be, and we&apos;re ready to show you
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="services__card">
              <div className="services__card__icon">
                <img className="icon-dark" src={Images.icon2} alt="icon" />
                <img
                  className="icon-white"
                  src={Images.icon2White}
                  alt="icon-1-white"
                />
              </div>
              <div className="services__card__title">
                <h3>Mobile Development</h3>
              </div>
              <div className="services__card__desc">
                <p>
                  Nonstop services of mobile application development to establish a connect business eco-system
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="services__card">
              <div className="services__card__icon">
                <img className="icon-dark" src={Images.icon3} alt="icon" />
                <img
                  className="icon-white"
                  src={Images.icon3White}
                  alt="icon-1-white"
                />
              </div>
              <div className="services__card__title">
                <h3>Product Design </h3>
              </div>
              <div className="services__card__desc">
                <p>
                  Our highly creative team always help you all the way to build an exciting product for your business
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="services__card">
              <div className="services__card__icon">
                <img className="icon-dark" src={Images.icon4} alt="icon" />
                <img
                  className="icon-white"
                  src={Images.icon4White}
                  alt="icon-1-white"
                />
              </div>
              <div className="services__card__title">
                <h3>Maintenance & Support </h3>
              </div>
              <div className="services__card__desc">
                <p>
                  Professional maintenance & support services match your needs
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
);
