import React from "react";
import "../../scss/layouts/_our_clients.scss";
import Images from "../../assets";

export const Clients: React.FC<any> = () => (
  <>
    <section className="our_clients-section">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="section__head">
              <span className="section-shape">
                <img src={Images.clientShape} alt="client-shape" />
              </span>
              <div className="section_head_level">
                <span className="level-line"></span>
                <p>our loyal clients</p>
              </div>
              <h3>Our Featured Clients </h3>
              <p className="font-weight-light">
                Providing the highest quality design and development solutions to all our clients
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="row no-gutters custom_margin">
        <div className="col-lg-3 col-sm-6">
          <div className="client__card">
            <img className="img-dark" src={Images.sky} alt="sky logo" />
          </div>
        </div>
        <div className="col-lg-3 col-sm-6">
          <div className="client__card">
            <img className="img-dark" src={Images.nhs} alt="nhs logo" />
          </div>
        </div>
        <div className="col-lg-3 col-sm-6">
          <div className="client__card">
            <img className="img-dark" src={Images.motronix} alt="motronix logo" />
          </div>
        </div>
        <div className="col-lg-3 col-sm-6">
          <div className="client__card">
            <img className="img-dark" src={Images.esso} alt="esso logo" />
          </div>
        </div>
        <div className="col-lg-3 col-sm-6">
          <div className="client__card">
            <img className="img-dark" src={Images.tetra_pak} alt="tetra_pak logo" />
          </div>
        </div>
        <div className="col-lg-3 col-sm-6">
          <div className="client__card">
            <img className="img-dark" src={Images.ed} alt="ed logo" />
          </div>
        </div>
        <div className="col-lg-3 col-sm-6">
          <div className="client__card">
            <img className="img-dark" src={Images.edf} alt="edf logo" />
          </div>
        </div>
        <div className="col-lg-3 col-sm-6">
          <div className="client__card">
            <img className="img-dark" src={Images.sainsbury} alt="sainsbury logo" />
          </div>
        </div>
        <div className="col-lg-3 col-sm-6">
          <div className="client__card">
            <img className="img-dark" src={Images.wilko} alt="wilko logo" />
          </div>
        </div>
        <div className="col-lg-3 col-sm-6">
          <div className="client__card">
            <img className="img-dark" src={Images.goethe} alt="goethe logo" />
          </div>
        </div>
        <div className="col-lg-3 col-sm-6">
          <div className="client__card">
            <img className="img-dark" src={Images.ucas} alt="ucas logo" />
          </div>
        </div>
        <div className="col-lg-3 col-sm-6">
          <div className="client__card">
            <img className="img-dark" src={Images.garmin} alt="garmin logo" />
          </div>
        </div>
      </div>
    </section>
  </>
);
