const path = "./images/";

const Images = {
  heroImage: require(path + "hero-img.svg"),
  clientShape: require(path + "client-shape.svg"),
  banner2: require(path + "banner2.png"),
  recentDot: require(path + "recent_dot-shape.svg"),
  whatWeShape: require(path + "what_we-shape.svg"),
  whatWe1: require(path + "what_we-1.svg"),
  whatWe2: require(path + "what_we-2.svg"),
  whatWe3: require(path + "what_we-3.svg"),
  graphic: require(path + "graphic.png"),
  heroQuoteImage: require(path + "hero_quote.svg"),

  icon1: require(path + "icon-1.svg"),
  icon2: require(path + "icon-2.svg"),
  icon3: require(path + "icon-3.svg"),
  icon4: require(path + "icon-4.svg"),

  icon1White: require(path + "icon-1-white.svg"),
  icon2White: require(path + "icon-2-white.svg"),
  icon3White: require(path + "icon-3-white.svg"),
  icon4White: require(path + "icon-4-white.svg"),

  webDBlack: require(path + "web-d-black.svg"),
  webDGray: require(path + "web-d-gray.svg"),
  webDWhite: require(path + "web-d-white.svg"),
  uxDesignBlack: require(path + "UXUI-Design-black.svg"),
  uxDesignGray: require(path + "UXUI-Design-gray.svg"),
  uxDesignWhite: require(path + "UXUI-Design-white.svg"),
  mobileAppBlack: require(path + "Mobile-App-black.svg"),
  mobileAppGray: require(path + "Mobile-App-gray.svg"),
  mobileAppWhite: require(path + "Mobile-App-white.svg"),
  brandingBlack: require(path + "Branding-black.svg"),
  brandingGray: require(path + "Branding-gray.svg"),
  brandingWhite: require(path + "Branding-white.svg"),

  logo1: require(path + "clients/dark/logo-1.svg"),
  logo2: require(path + "clients/dark/logo-2.svg"),
  logo3: require(path + "clients/dark/logo-3.svg"),
  logo4: require(path + "clients/dark/logo-4.svg"),
  logo5: require(path + "clients/dark/logo-5.svg"),
  logo6: require(path + "clients/dark/logo-6.svg"),
  logo7: require(path + "clients/dark/logo-7.svg"),
  logo8: require(path + "clients/dark/logo-8.svg"),
  logo9: require(path + "clients/dark/logo-9.svg"),
  logo10: require(path + "clients/dark/logo-10.svg"),
  logo11: require(path + "clients/dark/logo-11.svg"),
  logo12: require(path + "clients/dark/logo-12.svg"),

  logoDark: require(path + "logo-dark.svg"),
  logo: require(path + "logo.svg"),
  logoWhite: require(path + "logo-white.svg"),

  portfolio_dreamsfactory: require(path + "portfolio/dreamsfactory.png"),
  portfolio_driverguardian: require(path + "portfolio/driverguardian.png"),
  portfolio_enchanting_forms: require(path + "portfolio/enchanting_forms.png"),
  portfolio_realtimecv: require(path + "portfolio/realtimecv.png"),
  portfolio_silk_sleek: require(path + "portfolio/silk_sleek.png"),
  portfolio_creative_invoice: require(path + "portfolio/creative_invoice.png"),

  agile: require(path + "about/agile.svg"),
  methodology: require(path + "about/methodology.svg"),
  office: require(path + "about/office.png"),

  sky: require(path + "clients/sky.png"),
  nhs: require(path + "clients/nhs.png"),
  motronix: require(path + "clients/motronix.png"),
  esso: require(path + "clients/esso.png"),
  tetra_pak: require(path + "clients/tetra_pak.png"),
  ed: require(path + "clients/ed.png"),
  edf: require(path + "clients/edf.png"),
  sainsbury: require(path + "clients/sainsbury.png"),
  wilko: require(path + "clients/wilko.png"),
  goethe: require(path + "clients/goethe.png"),
  ucas: require(path + "clients/ucas.png"),
  garmin: require(path + "clients/garmin.png"),
};

export default Images;
