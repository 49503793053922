import React from "react";
import { Admin } from "./Admin";
import { Frontend } from "./Frontend";

export const AppRoutes: React.FC<any> = () => (
  <>
    <Frontend />
    <Admin />
  </>
);
