import React from "react";
import "../../scss/layouts/_get_touch.scss";

export const GetTouch: React.FC<any> = () => (
  <>
    <section className="get_touch-section">
      <div className="container">
        <div className="row">
          <div className="col-md-8 offset-md-2">
            <div className="get_touch__inner">
              <div className="recent_level_box">
                <span className="recent_level_line"></span>
                <p>contact us</p>
              </div>
              <h2>Get in touch</h2>
              <h3>
                Share your exciting business idea and get an estimate
              </h3>
              <div className="text-right">
                <div className="btn_wrap">
                  <button className="btn btn-outline-secondary" onClick={()=>{window.location.href='/quote'}}>
                    contact us <span className="view__line"></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
);
