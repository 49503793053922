import React from "react";
import { Header } from "../Layout/Header";
import { ProjectType } from "./ProjectType";
import { QuoteBanner } from "./QuoteBanner";

const Quote = () => {
  return (
    <>
      <Header className="header_dark" />
      <QuoteBanner />
      <ProjectType />
    </>
  );
};

export default Quote;
