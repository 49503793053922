import React from "react";
import { Routes, Route } from "react-router-dom";
import { Footer } from "../Layout/Footer";
import Main from "../Main/Main";
import { NotFound } from "../Layout/NotFound";
import Quote from "../Quote/Quote";
import "../../css/fontawesome.min.css";
import "../../css/main.css";
import "../../css/main.css.map";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.min.js";
import AboutUs from "../AboutUs/AboutUs";
import Services from "../Services/Services";
import PortfolioMain from "../Portfolio/PortfolioMain";

export const Frontend: React.FC<any> = () => (
  <>
    <Routes>
      <Route path="/" element={<Main />}/>
      <Route path="/quote" element={<Quote />}/>
      <Route path="/about-us" element={<AboutUs />}/>
      <Route path="/services" element={<Services />}/>
      <Route path="/portfolio" element={<PortfolioMain />}/>
      <Route path="*" element={<NotFound />} />
    </Routes>
    <Footer />
  </>
);
