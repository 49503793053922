import React from "react";
import { Link } from "react-router-dom";
import "../../scss/layouts/_header.scss";
import Images from "../../assets";

interface HeaderProps {
  className?: string;
}

export const Header: React.FC<HeaderProps> = (props) => (
  <>
    <header className={"header-section " + (props.className || "")}>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <nav className="navbar navbar-expand-lg navbar-light">
              <Link to="/" className="navbar-brand">
                <img className="logo-dark" src={Images.logo} alt="logo" />
                <img
                  className="logo-white"
                  src={Images.logoWhite}
                  alt="logo-white"
                />
              </Link>

              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#mobile_expand"
              >
                <span className="fal fa-bars"></span>
              </button>

              <div className="collapse navbar-collapse" id="mobile_expand">
                <ul className="navbar-nav ml-auto">
                  <li className="nav-item">
                    <Link to="/about-us" className="nav-link">
                      About Us{" "}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/portfolio" className="nav-link">
                      Our Work{" "}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/services" className="nav-link">
                      Services We offer{" "}
                    </Link>
                  </li>


                </ul>
                <form action="#">
                  <Link to="/quote" className="btn text-white rounded-pill">
                    {" "}
                    <span className="btn-dot"></span> Estimate Project{" "}
                  </Link>
                </form>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </header>
  </>
);
