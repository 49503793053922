import React from "react";
import { AppRoutes } from "./components/AppRoutes/AppRoutes";

const App: React.FC<any> = () => {
  return (
    <>
      <AppRoutes />
    </>
  );
};

export default App;
