import React from "react";
import Typed from "react-typed";
import "../../scss/layouts/_hero.scss";
import Images from "../../assets";

export const Banner: React.FC<any> = () => {
  const textLines = ["We Design &#38; Develop Outstanding <br> Products"];
  const typeSpeed = 60;
  return (
    <>
      <section className="hero-section">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="hero__content">
                <div className="hero__content__verticall_lebel">
                  <div className="text-rotate">
                    <span>4d creative studios</span>
                    <span className="line_dot"></span>
                    <span>award winning agency</span>
                  </div>
                </div>
                <div className="hero__content__title">
                  <h1>
                    <Typed strings={textLines} typeSpeed={typeSpeed} />
                    <span id="quote"></span>
                  </h1>
                </div>
                <div className="hero__content__desc">
                  <p>
                    Bespoke digital solutions since 2012
                  </p>
                </div>
                <div className="hero__content__social">
                  <ul>
                    <li>
                      <a href="https://facebook.com/4dcreativestudios/" target="_blank" rel="noreferrer">
                        {" "}
                        <i className="fab fa-facebook-f"></i>{" "}
                      </a>
                    </li>
                    <li>
                      <a href="https://uk.linkedin.com/in/4d-studios-7b51b028" target="_blank" rel="noreferrer">
                        {" "}
                        <i className="fab fa-linkedin-in"></i>{" "}
                      </a>
                    </li>
                    <li>
                      <a href="https://www.twitter.com/4dstudiosUK"  target="_blank" rel="noreferrer">
                        {" "}
                        <i className="fab fa-twitter"></i>{" "}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="hero__showcase">
                <img src={Images.heroImage} alt="hero-img" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
