import React from "react";
import "../../scss/layouts/_recent-work.scss";
import "../../scss/layouts/_what_we.scss";
import "../../scss/layouts/_our_work.scss";
import Images from "../../assets";
import {WhatWe} from "./WhatWe";

export const Portfolio: React.FC<any> = () => {

  const openExternalLink=(link: string)=>{
    window.open(link,'_blank');
  }




  return (
      <>
    <section className="recent-work">
      <div className="recent_right_banner">
        <img src={Images.banner2} alt="banner2" />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-10">
            <div className="recent_work__card">
              <div className="recent_level_box">
                <span className="recent_level_line"></span>
                <p>4D creative studios</p>
              </div>
              <div className="recent__card__title">
                <h3>
                  We’re a creative digital studio ccommitted to creating cutting-edge web experiences.
                </h3>
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="recent_dot_shape">
              <img src={Images.recentDot} alt="recent_dot" />
            </div>
          </div>
        </div>
      </div>
    </section>
   <WhatWe/>
    <section className="our_work-section">
      <div className="container bg-shape">
        <div className="row">
          <div className="col-md-12">
            <div className="section__head">
              <div className="section_head_level">
                <span className="level-line"></span>
                <p>our recent work</p>
              </div>
              <h3>
                Offering creative and innovative solutions to a global clientele
              </h3>
            </div>
          </div>
        </div>
        <div className="row custom_space mt-5">
          <div className="col-md-6">
            <div className="our_work__card">
              <div className="btn__wrap">
                <button className="btn btn-outline-secondary" onClick={()=>{window.location.href='/portfolio'}}>
                  view our work <span className="view__line"></span>
                </button>
              </div>
            </div>

            <div className="our_work__card" onClick={()=>{openExternalLink('https://dreamsfactory.co.uk')}}>
              <div className="our_work__card__cover">
                <img src={Images.portfolio_dreamsfactory} alt="Dreams Factory" />
              </div>
              <div className="our_work__card__content">
                <h3>Dreamsfactory</h3>
                <p>Photography / Branding/ Subscriptions</p>
              </div>
            </div>
            <div className="our_work__card" onClick={()=>{openExternalLink('https://driverguardian.co.uk')}}>
              <div className="our_work__card__cover">
                <img src={Images.portfolio_driverguardian} alt="graphic" />
              </div>
              <div className="our_work__card__content">
                <h3>Driver Guardian</h3>
                <p>Automotive / E-Commerce / Insurance</p>
              </div>
            </div>
            <div className="our_work__card" onClick={()=>{openExternalLink('https://enchantingforms.com')}}>
              <div className="our_work__card__cover">
                <img src={Images.portfolio_enchanting_forms} alt="graphic" />
              </div>
              <div className="our_work__card__content">
                <h3>Enchanting Forms</h3>
                <p>Branding / Beauty / Digital Media</p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="our_work__card" onClick={()=>{openExternalLink('https://realtimecv.com')}}>
              <div className="our_work__card__cover">
                <img src={Images.portfolio_realtimecv} alt="graphic" />
              </div>
              <div className="our_work__card__content">
                <h3>Realtime CV</h3>
                <p>Human Resources / Subscriptions</p>
              </div>
            </div>
            <div className="our_work__card" onClick={()=>{openExternalLink('https://silksleek.co.uk')}}>
              <div className="our_work__card__cover">
                <img src={Images.portfolio_silk_sleek} alt="graphic" />
              </div>
              <div className="our_work__card__content">
                <h3>Silk Sleek</h3>
                <p>Retail / E-commerce / Luxury</p>
              </div>
            </div>

            <div className="our_work__card" onClick={()=>{openExternalLink('http://www.creativeinvoices.com')}}>
              <div className="our_work__card__cover">
                <img src={Images.portfolio_creative_invoice} alt="graphic" />
              </div>
              <div className="our_work__card__content">
                <h3>Creative Invoices</h3>
                <p>Finance / Digital Media</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
);
}