import React from "react";
import "../../scss/layouts/_hero.scss";

export const PortfolioBanner: React.FC<any> = () => (
    <>
      <section className="hero-section header_dark">
        <div className="container">
          <div className="row">
            <div className="col-sm-6 col-lg-5">
              <div className="hero__content">
                <div className="hero__content__verticall_lebel">
                  <div className="text-rotate">
                  <span>
                    <a href="">4D Portfolio</a>
                  </span>
                  </div>
                </div>
                <div className="hero__content__desc">
                  <h3>Our Work</h3>
                  <p>We are good what we have design and developed</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
);
