import React from "react";
import "../../scss/layouts/_our_clients.scss";

export const ServicesContainer: React.FC<any> = () => (

    <section className="what_we-section" style={{backgroundColor: '#fff'}}>

        <div className="container">


            <div className="row mt-5">
                <div className="col-md-12">
                    <div className="what__we__card">
                        <div className="what__we__card__title">
                            <h3>Research & Analytics</h3>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="what__we__card">
                        <div className="what__we__card__desc">
                            <ul>
                                <li>Information Architecture </li>
                                <li>Product strategy </li>
                                <li>Research Opportunities </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="what__we__card">
                        <div className="what__we__card__desc">
                            <ul>
                                <li>Analyse client business </li>
                                <li>Target audience </li>
                                <li>Production planning </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="what__we__card">
                        <div className="what__we__card__desc">
                            <ul>
                                <li> Surveys </li>
                                <li>Wireframing </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-5">
                <div className="col-md-12">
                    <div className="what__we__card">
                        <div className="what__we__card__title">
                            <h3>Product Design Services</h3>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="what__we__card">
                        <div className="what__we__card__desc">
                            <ul>
                                <li>User interface design </li>
                                <li>Branding </li>
                                <li>Prototyping and Testing </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="what__we__card">
                        <div className="what__we__card__desc">
                            <ul>
                                <li>UI Kits & Libraries </li>
                                <li>E-commerce </li>
                                <li>Apps iOS & Android </li>
                            </ul>
                        </div>
                    </div>
                </div>


                <div className="col-md-3">
                    <div className="what__we__card">
                        <div className="what__we__card__desc">
                            <ul>
                                <li>Product Design </li>
                                <li>User experience design </li>
                                <li>Illustrations </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-5">
                <div className="col-md-12">
                    <div className="what__we__card">
                        <div className="what__we__card__title">
                            <h3>Mobile development services</h3>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="what__we__card">
                        <div className="what__we__card__desc">
                            <ul>
                                <li>Native iOS Development (Swift) </li>
                                <li>Native Android Development (Java/Kotlin) </li>
                                <li> React Native Development for iOS/Android </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mt-5">
                <div className="col-md-12">
                    <div className="what__we__card">
                        <div className="what__we__card__title">
                            <h3>Web Development & Integration Services</h3>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="what__we__card">
                        <div className="what__we__card__desc">
                            <ul>
                                <li>Front-end Development </li>
                                <li>Development Consulting </li>
                                <li>CMS Bespoke Development </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="what__we__card">
                        <div className="what__we__card__desc">
                            <ul>
                                <li>REST API Development/Integration </li>
                                <li>React Web Apps Development </li>
                                <li>SQL Database Development </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="what__we__card">
                        <div className="what__we__card__desc">
                            <ul>
                                <li>HTML/CSS/JS </li>
                                <li>Payment Gateways </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>


            <div className="row mt-5">
                <div className="col-md-12">
                    <div className="what__we__card">
                        <div className="what__we__card__title">
                            <h3>Support and management services</h3>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="what__we__card">
                        <div className="what__we__card__desc">
                            <ul>
                                <li>Product management </li>
                                <li>Branding Strategy </li>
                                <li>Web API Support </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="what__we__card">
                        <div className="what__we__card__desc">
                            <ul>
                                <li>Cybersecurity </li>
                                <li>Cloud technology </li>
                                <li>Project management </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="what__we__card">
                        <div className="what__we__card__desc">
                            <ul>
                                <li>SEO </li>
                                <li>Website Maintenance </li>
                                <li>Social Media </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mt-5">
                <div className="col-md-12">
                    <div className="what__we__card">
                        <h3 className="font-weight-light mb-3">We are expert in building</h3>
                    </div>
                </div>

                <div className="col-md-3">
                    <div className="what__we__card">
                        <div className="what__we__card__desc">
                            <ul>
                                <li>Ecommerce websites </li>
                                <li>Photography websites </li>
                                <li>Vehicle marketplace websites </li>
                                <li>Complete vehicle web applications </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="what__we__card">
                        <div className="what__we__card__desc">
                            <ul>
                                <li>Custom backend design & development </li>
                                <li>Recruitment agency web applications </li>
                                <li> SaaS </li>
                            </ul>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </section>
);

