import React from "react";
import "../../scss/layouts/_hero.scss";
import Images from "../../assets";

export const QuoteBanner: React.FC<any> = () => (
  <>
    <section className="hero-section header_dark">
      <div className="container">
        <div className="row">
          <div className="col-sm-6 col-lg-5">
            <div className="hero__content">
              <div className="hero__content__verticall_lebel">
                <div className="text-rotate">
                  <span>
                    <a href="#">contact us</a>
                  </span>
                </div>
              </div>

              <div className="hero__content__desc">
                <h3>Get an estimate</h3>
                <p>
                  Fill out this simple form. Our team will contact you promptly
                  to discuss next steps.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="hero__showcase hero__showcase--quote">
              <img src={Images.heroQuoteImage} alt="hero-img" />
            </div>
          </div>
          <div className="quote__box">
            <span>EMAIL</span>
            <a href="mailto:hello@4d-studios.co.uk" className="quote_mail">
              hello@4d-studios.co.uk
            </a>

            <span>STUDIO</span>


            <address>
              4D Creative Studios<br />
              4-5 Lochside Way <br />
              Edinburgh Park <br />
              Edinburgh <br />
              EH12 9DT <br />
            </address>


            <span>Social</span>
            <ul>
              <li>
                <a href="https://uk.linkedin.com/in/4d-studios-7b51b028" target="_blank" rel="noreferrer">
                  {" "}
                  <i className="fab fa-linkedin-in"></i>{" "}
                </a>
              </li>
              <li>
                <a href="https://www.twitter.com/4dstudiosUK"  target="_blank" rel="noreferrer">
                  {" "}
                  <i className="fab fa-twitter"></i>{" "}
                </a>
              </li>
              <li>
                <a href="https://facebook.com/4dcreativestudios/" target="_blank" rel="noreferrer">
                  {" "}
                  <i className="fab fa-facebook-f"></i>{" "}
                </a>
              </li>
              <li>
                <a href="https://dribbble.com/4dstudios" target="_blank" rel="noreferrer">
                  {" "}
                  <i className="fab fa-dribbble"></i>{" "}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </>
);
