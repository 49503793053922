import React, {useRef, useState} from "react";
import {Formik, Form, Field, ErrorMessage, FormikFormProps, FormikProps, FormikValues} from "formik";
import * as Yup from "yup";
import "../../scss/layouts/_quote.scss";
import Images from "../../assets";
import axios from "axios";

const initialValues = {
  name: "",
  phone: "",
  email: "",
  company: "",
  project_budget: "",
  about_us: "",
  project_desc: "",
  //agree_check: "",
};

const validationSchema = Yup.object({
  name: Yup.string().required("Please enter your name"),
  phone: Yup.string().required("Please provide your contact number"),
  email: Yup.string()
    .email("Email must be formatted correctly")
    .required("Please enter your email address"),
  company: Yup.string().required("Please enter your company name"),
  project_budget: Yup.string().required("Please select project budget"),
  about_us: Yup.string().required("Please select where did you hear about us"),
  project_desc: Yup.string().required("Please enter the project description"),
  //agree_check: Yup.bool().required("Please accept term and condition"),
});
const onSubmit = (values: any, onSubmitProps: any) => {


  const url='https://www.realtimecv.com/site/4d';

  axios.get(url, {
    params: values
  }).then(()=>{
    setTimeout(() => {
      window.scrollTo(0, 600);
      onSubmitProps.setSubmitting(false);
      onSubmitProps.resetForm();
    }, 1000);

  });



};

const projectBudgetOptions = [
  { key: "Select an Option", value: "" },
  { key: "£2000 - £4000", value: "£2000 - £4000" },
  { key: "£4000 - £8000", value: "£4000 - £8000" },
  { key: "£8000 - £25000", value: "£8000 - £25000" },
  { key: "£25000+", value: "£25000+" }
];


const hasError= (formik: any, field: string)=>{
 if(formik.touched[field])
 {
   return formik.errors[field]
 }
}




const aboutUsOptions = [
  { key: "Select an Option", value: "" },
  { key: "Google", value: "Google" },
  { key: "Facebook", value: "Facebook" },
  { key: "Word of mouth", value: "Word of mouth" },
  { key: "Blog post", value: "Blog post" },
  { key: "Other", value: "Other" },
];

const projectTypes = {
  web: "web",
  ui: "ui",
  mobile: "mobile",
  branding: "branding",
};

export const ProjectType: React.FC<any> = () => {
  const [projectType, setProjectType] = useState([]);
  const [webDevChecked, setWebDevChecked] = useState(false);
  const [uIChecked, setUIChecked] = useState(false);
  const [mobileChecked, setMobileChecked] = useState(false);
  const [brandingChecked, setBrandingChecked] = useState(false);

  const toggleBox=(type: string)=>{
    switch (type)
    {
      case 'webDev':
        setWebDevChecked(!webDevChecked);
        return;
      case 'ui':
        setUIChecked(!uIChecked);
        return;
      case 'mobile':
        setMobileChecked(!mobileChecked);
        return;
      case 'brand':
        setBrandingChecked(!brandingChecked);
        return;
    }
  }
  return (
    <>
      <section className="project-type">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <div className="row">
                <div className="col-md-12 mt-md-4">
                  <div className="success-message d-none">
                    <div className="alert alert-success">Your contact query has been received. We will be in touch shortly.</div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="project_type_title">
                    <h3>Project Type</h3>
                    <p>Please select the project</p>
                  </div>

                </div>

                <div className="col-md-12">
                  <div className="project_type_box__wrap">
                    <div
                      className={
                        webDevChecked
                          ? " project_type_box project_type_box--active"
                          : "project_type_box"
                      }
                      onClick={()=>{toggleBox('webDev')}}
                    >
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="web-dev"
                          checked={webDevChecked}
                          onChange={() => {
                            setWebDevChecked(!webDevChecked);
                          }}

                        />
                        <label
                          className="custom-control-label"
                          htmlFor="web-dev"
                        >
                          &nbsp;
                        </label>
                      </div>
                      <div className="project_type_box__icon">
                        <img
                          className="img-type-black"
                          src={Images.webDBlack}
                          alt="Web Development"
                        />
                        <img
                          className="img-type-gray"
                          src={Images.webDGray}
                          alt="Web Development"
                        />
                        <img
                          className="img-type-white"
                          src={Images.webDWhite}
                          alt="Web Development"
                        />
                      </div>
                      <h3>Web Development</h3>
                    </div>
                    <div
                      className={
                        uIChecked
                          ? " project_type_box project_type_box--active"
                          : "project_type_box"
                      }
                      onClick={()=>{toggleBox('ui')}}
                    >
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="UXUI-check"
                          checked={uIChecked}
                          onChange={() => {
                            setUIChecked(!uIChecked);
                          }}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="UXUI-check"
                        >
                          &nbsp;
                        </label>
                      </div>
                      <div className="project_type_box__icon">
                        <img
                          className="img-type-black"
                          src={Images.uxDesignBlack}
                          alt="UXUI-Design"
                        />
                        <img
                          className="img-type-gray"
                          src={Images.uxDesignGray}
                          alt="UXUI-Design"
                        />
                        <img
                          className="img-type-white"
                          src={Images.uxDesignWhite}
                          alt="UXUI-Design"
                        />
                      </div>
                      <h3>UXUI Design</h3>
                    </div>
                    <div
                      className={
                        mobileChecked
                          ? " project_type_box project_type_box--active"
                          : "project_type_box"
                      }
                      onClick={()=>{toggleBox('mobile')}}
                    >
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="mobile-check"
                          checked={mobileChecked}
                          onChange={() => {
                            setMobileChecked(!mobileChecked);
                          }}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="mobile-check"
                        >
                          &nbsp;
                        </label>
                      </div>
                      <div className="project_type_box__icon">
                        <img
                          className="img-type-black"
                          src={Images.mobileAppBlack}
                          alt="Mobile-App"
                        />
                        <img
                          className="img-type-gray"
                          src={Images.mobileAppGray}
                          alt="Mobile-App"
                        />
                        <img
                          className="img-type-white"
                          src={Images.mobileAppWhite}
                          alt="Mobile-App"
                        />
                      </div>
                      <h3>Mobile App</h3>
                    </div>
                    <div
                      className={
                        brandingChecked
                          ? " project_type_box project_type_box--active"
                          : "project_type_box"
                      }
                      onClick={()=>{toggleBox('brand')}}
                    >
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="branding-check"
                          checked={brandingChecked}
                          onChange={() => {
                            setBrandingChecked(!brandingChecked);
                          }}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="branding-check"
                        >
                          &nbsp;
                        </label>
                      </div>
                      <div className="project_type_box__icon">
                        <img
                          className="img-type-black"
                          src={Images.brandingBlack}
                          alt="Branding"
                        />
                        <img
                          className="img-type-gray"
                          src={Images.brandingGray}
                          alt="Branding"
                        />
                        <img
                          className="img-type-white"
                          src={Images.brandingWhite}
                          alt="Branding"
                        />
                      </div>
                      <h3>Branding</h3>
                    </div>
                  </div>
                </div>
              </div>

              <div className="project_form">
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                  //validateOnBlur={false}
                  //validateOnChange={false}
                  //validateOnMount
                >
                  {(formik) => {

                    return (
                      <Form>
                        <div className="row mt-5">
                          <div className="col-md-6">
                            <div className={`type__form ${hasError(formik,'name')?'type__form--error':''}`}>
                              <label htmlFor="your_name">
                                Your Name <span>*</span>
                              </label>
                              <Field
                                id="name"
                                name="name"
                                type="text"
                                className="form-control"
                                placeholder="Richard Wilson"
                              />
                              <ErrorMessage name="name">
                                {(errorMsg) => (
                                  <span className="has_error">{errorMsg}</span>
                                )}
                              </ErrorMessage>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className={`type__form ${hasError(formik,'phone')?'type__form--error':''}`}>
                              <label htmlFor="Phone">
                                Phone <span>*</span>
                              </label>
                              <Field
                                id="phone"
                                name="phone"
                                type="text"
                                className="form-control"
                                placeholder="Phone Number"
                              />
                              <ErrorMessage name="phone">
                                {(errorMsg) => (
                                  <span className="has_error">{errorMsg}</span>
                                )}
                              </ErrorMessage>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className={`type__form ${hasError(formik,'email')?'type__form--error':''}`}>
                              <label htmlFor="Email">
                                Email <span>*</span>
                              </label>
                              <Field
                                id="email"
                                name="email"
                                type="text"
                                className="form-control"
                                placeholder="Email address"
                              />
                              <ErrorMessage name="email">
                                {(errorMsg) => (
                                  <span className="has_error">{errorMsg}</span>
                                )}
                              </ErrorMessage>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className={`type__form ${hasError(formik,'company')?'type__form--error':''}`}>
                              <label htmlFor="Company">
                                Company <span>*</span>
                              </label>
                              <Field
                                id="company"
                                name="company"
                                type="text"
                                className="form-control"
                                placeholder="Business or Company Name"
                              />
                              <ErrorMessage name="company">
                                {(errorMsg) => (
                                  <span className="has_error">{errorMsg}</span>
                                )}
                              </ErrorMessage>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className={`type__form ${hasError(formik,'project_budget')?'type__form--error':''}`}>
                              <label htmlFor="Project_Budget">
                                Project Budget <span>*</span>
                              </label>
                              <Field
                                as="select"
                                name="project_budget"
                                id="project_budget"
                                className="custom-select"
                              >
                                {projectBudgetOptions.map((option) => {
                                  return (
                                    <option
                                      key={option.key}
                                      value={option.value}
                                    >
                                      {option.key}
                                    </option>
                                  );
                                })}
                              </Field>
                              <ErrorMessage name="project_desc">
                                {(errorMsg) => (
                                  <span className="has_error">{errorMsg}</span>
                                )}
                              </ErrorMessage>{" "}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className={`type__form ${hasError(formik,'about_us')?'type__form--error':''}`}>
                              <label htmlFor="about_us">
                                How did you hear about us? <span>*</span>
                              </label>
                              <Field
                                as="select"
                                name="about_us"
                                id="about_us"
                                className="custom-select"
                              >
                                {aboutUsOptions.map((option) => {
                                  return (
                                    <option
                                      key={option.key}
                                      value={option.value}
                                    >
                                      {option.key}
                                    </option>
                                  );
                                })}
                              </Field>
                              <ErrorMessage name="about_us">
                                {(errorMsg) => (
                                  <span className="has_error">{errorMsg}</span>
                                )}
                              </ErrorMessage>{" "}
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className={`type__form ${hasError(formik,'project_desc')?'type__form--error':''}`}>
                              <label htmlFor="project_desc">
                                Project Description<span>*</span>
                              </label>
                              <Field
                                as="textarea"
                                placeholder="Tell us about your project"
                                id="project_desc"
                                name="project_desc"
                                className="form-control"
                              />
                              <ErrorMessage name="project_desc">
                                {(errorMsg) => (
                                  <span className="has_error" style={{top: '225px'}}>{errorMsg}</span>
                                )}
                              </ErrorMessage>{" "}
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className={`type__form ${hasError(formik,'agree_check')?'type__form--error':''}`}>
                              <span>
                                4D Cteative Studios is committed to processing
                                the above information in order to contact you
                                and talk about your project. Other information
                                is used for statistical purposes and, from time
                                to time, we would like to contact you about our
                                other products and services, as well as other
                                content that may be of interest to you. If you
                                consent to contact you for these purposes,
                                please tick below:
                              </span>
                              <div className="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id="agree_check"
                                  name="agree_check"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="agree_check"
                                >
                                  I agree to receive marketing communication
                                  from 4D Creative Studios.
                                </label>
                                <ErrorMessage name="agree_check">
                                  {(errorMsg) => (
                                    <span className="has_error">
                                      {errorMsg}
                                    </span>
                                  )}
                                </ErrorMessage>{" "}
                              </div>
                              <div className="btn__wrap">
                                <button
                                  type="submit"
                                  className="btn btn-outline-secondary"
                                  disabled={
                                    !formik.isValid || formik.isSubmitting
                                  }
                                >
                                  Submit <span className="view__line"></span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="mobile__only">
        <div className="quote__box">
          <span>EMAIL</span>
          <a href="mailto:hello@4d-studios.co.uk" className="quote_mail">
            hello@4d-studios.co.uk
          </a>

          <span>STUDIO</span>
          <address>
            4D Creative Studios<br />
            4-5 Lochside Way <br />
            Edinburgh Park <br />
            Edinburgh <br />
            EH12 9DT <br />
          </address>

          <span>Social</span>
          <ul>
            <li>
              <a href="https://uk.linkedin.com/in/4d-studios-7b51b028" target="_blank" rel="noreferrer">
                {" "}
                <i className="fab fa-linkedin-in"></i>{" "}
              </a>
            </li>
            <li>
              <a href="https://www.twitter.com/4dstudiosUK"  target="_blank" rel="noreferrer">
                {" "}
                <i className="fab fa-twitter"></i>{" "}
              </a>
            </li>
            <li>
              <a href="https://facebook.com/4dcreativestudios/" target="_blank" rel="noreferrer">
                {" "}
                <i className="fab fa-facebook-f"></i>{" "}
              </a>
            </li>
            <li>
              <a href="https://dribbble.com/4dstudios" target="_blank" rel="noreferrer">
                {" "}
                <i className="fab fa-dribbble"></i>{" "}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
