import React from "react";
import "../../scss/layouts/_our_clients.scss";
import Images from "../../assets";

export const AboutServices: React.FC<any> = () => (


    <section className="what_we-section">
        <div className="about-img">
            <img src={Images.office} alt="4D studios office" className={"img-fluid hidd"} style={{position: 'relative',bottom: '325px',right: '100px'}}/>
        </div>
        <div className="path_top"></div>
        <div className="path_bottom"></div>
        <div className="what_we-shape">
            <img src={Images.whatWeShape} alt="what_we-shape" />
        </div>
        <div className="container pt-5">

            <div className="row pt-5 pb-5">
                <div className="col-md-12">
                    <h3 className="font-weight-light">Bespoke digital solutions since 2012</h3>
                    <div style={{fontWeight: '300'}} className="mt-3">
                        We’ve been delivering a comprehensive - and affordable range of services for over 12 years. From design and development, and business applications, to marketing and corporate communications, you name it; we’ve got the expertise to really enhance your business.

                        <br/><br/>

                        4D Studios is located in the beautiful and historic city of Edinburgh - each and every member of the 4D Studios team is passionate about bringing together state of the art technology and a dedicated, professional outlook to every individual and every company we work with.
                        <br/><br/>
                        It is this experience, dedication, and many years of expertise and specialist knowledge in each of our respective areas, that will really help you expand your business, and enhance your offering on the digital platform.  We are expert in building
                        <br/><br/>Simply! we need you and you need us. <br/><br/>


                        <strong>We’re specialists in:</strong> <br/><br/>

                        <ul className="specialities" style={{padding: '10px 0'}}>
                            <li style={{listStyleType: 'disc', marginLeft: '40px'}}>Complex vehicle web applications</li>
                            <li style={{listStyleType: 'disc', marginLeft: '40px'}}>Ecommerce websites</li>
                            <li style={{listStyleType: 'disc', marginLeft: '40px'}}>Photography websites</li>
                            <li style={{listStyleType: 'disc', marginLeft: '40px'}}>Vehicle marketplace websites</li>
                            <li style={{listStyleType: 'disc', marginLeft: '40px'}}>Complex vehicle web applications</li>
                            <li style={{listStyleType: 'disc', marginLeft: '40px'}}>Custom backend design & development</li>
                            <li style={{listStyleType: 'disc', marginLeft: '40px'}}>Recruitment agency web applications</li>
                            <li style={{listStyleType: 'disc', marginLeft: '40px'}}>SaaS</li>
                        </ul>

                    </div>


                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <h2 className="font-weight-light">What we do?</h2>
                </div>
            </div>
            <div className="row mt-5">
                <div className="col-md-3">
                    <div className="what__we__card">
                        <div className="what__we__card__icon">
                            <h3>W</h3>
                            <img src={Images.whatWe1} alt="what_we-1" />
                        </div>
                        <div className="what__we__card__title">
                            <h3>Web Development</h3>
                        </div>
                        <div className="what__we__card__desc">
                            <ul>
                                <li>Web Development </li>
                                <li>Progressive Web Apps </li>
                                <li>Custom Web Apps </li>
                                <li>CRM / CMS </li>
                                <li>Custom Programming </li>
                                <li>eCommerece Platform </li>
                                <li>Payment Gateway Integrations </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="what__we__card">
                        <div className="what__we__card__icon">
                            <h3>p</h3>
                            <img src={Images.whatWe2} alt="what_we-2" />
                        </div>
                        <div className="what__we__card__title">
                            <h3>Product Design</h3>
                        </div>
                        <div className="what__we__card__desc">
                            <ul>
                                <li>Product Design Sprint </li>
                                <li>Product Design </li>
                                <li>UX Review </li>
                                <li>Branding </li>
                                <li>App Design</li>
                                <li>Content Creation</li>
                                <li>Strategy & Positioning</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="what__we__card">
                        <div className="what__we__card__icon">
                            <h3>M</h3>
                            <img src={Images.whatWe3} alt="what_we-3" />
                        </div>
                        <div className="what__we__card__title">
                            <h3>Mobile Development</h3>
                        </div>
                        <div className="what__we__card__desc">
                            <ul>
                                <li>Android </li>
                                <li>iOS </li>
                                <li>React Native </li>
                                <li>Flutter </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">

                <div className="col-md-12 mt-5 mb-5">
                    <h2 style={{fontWeight: '300', marginBottom: '100px', textAlign: 'center'}}>Agile Development Process</h2>
                </div>
                <div className="col-md-12" style={{marginBottom: '100px', textAlign: 'center'}}>
                    <img src={Images.agile} alt="agile process" className={"img-fluid"}/>
                </div>
            </div>
        </div>

    </section>
);

