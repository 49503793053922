import React from "react";
import "../../scss/layouts/_hero.scss";
import Images from "../../assets";

export const AboutBanner: React.FC<any> = () => (
  <>
    <section className="hero-section header_dark">
      <div className="container">
        <div className="row">
          <div className="col-sm-6 col-lg-5">
            <div className="hero__content">
              <div className="hero__content__verticall_lebel">
                <div className="text-rotate">
                  <span>
                    <a href="">4D Creative Studios</a>
                  </span>
                </div>

              </div>
              <div className="hero__content__desc">
                <h3>Who We Are</h3>
                <p>We are highly creative & skilled team of designers and developers</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
);
