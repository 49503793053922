import React from "react";
import "../../scss/layouts/_footer.scss";
import Images from "../../assets";
import {Link} from "react-router-dom";

export const Footer: React.FC<any> = () => (
  <>
    <footer className="footer-section">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <div className="footer__card">
              <a href="#" className="footer__logo">
                {" "}
                <img src={Images.logoDark} alt="logo-dark" />{" "}
              </a>
              <p>CREATIVE STUDIOS</p>


              <address>
                4D Creative Studios<br />
                4-5 Lochside Way <br />
                Edinburgh Park <br />
                Edinburgh <br />
                EH12 9DT <br />
              </address>

              <a className="footer_mail" href="mailto:hello@4d-studios.co.uk">
                Email us <br />
                hello@4d-studios.co.uk
              </a>
            </div>
          </div>
          <div className="col-md-8">
            <div className="row">
              <div className="col-lg-3 col-sm-6">
                <div className="footer__card">
                  <ul className="footer__nav">
                    <li>
                      <Link to="/services">Services</Link>
                    </li>
                    <li>
                      <Link to="/portfolio">Portfolio</Link>
                    </li>
                    <li>
                      <Link to="/about-us">About</Link>
                    </li>
                    <li>
                      <Link to="/quote">Contact</Link>
                    </li>

                    <li>
                      <Link to="/quote">Request Quote</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="footer__card">
                  <h3>Product Design</h3>
                  <ul>
                    <li>
                      <Link to="/services">UI/UX Design</Link>
                    </li>
                    <li>
                      <Link to="/services">Branding</Link>
                    </li>
                    <li>
                      <Link to="/services">UI Kits & Libraries</Link>
                    </li>
                    <li>
                      <Link to="/services">E-commerce</Link>
                    </li>
                    <li>
                      <Link to="/services">Apps iOS & Android</Link>
                    </li>
                    <li>
                      <Link to="/services">Prototyping and Testing</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="footer__card">
                  <h3>Web Development</h3>
                  <ul>
                    <li>
                      <Link to="/services">CMS Bespoke</Link>
                    </li>
                    <li>
                      <Link to="/services">Frontend</Link>
                    </li>
                    <li>
                      <Link to="/services">HTML/CSS/JS</Link>
                    </li>
                    <li>
                      <Link to="/services">Payment gateways</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="footer__card">
                  <h3>Mobile App</h3>
                  <ul>
                    <li>
                      <Link to="/services">Native iOS</Link>
                    </li>
                    <li>
                      <Link to="/services">Native Android</Link>
                    </li>
                    <li>
                      <Link to="/services">React Native</Link>
                    </li>

                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row custom_margin">
          <div className="col-md-6">
            <div className="footer__copyright">
              <p>©2022 4D Creative Studios Ltd.</p>
              {/*<a href="#">Terms & Privacy</a>*/}
            </div>
          </div>
          <div className="col-md-6">
            <div className="footer_social">
              <ul>
                <li>
                  <a href="https://uk.linkedin.com/in/4d-studios-7b51b028" target="_blank" rel="noreferrer">
                    {" "}
                    <i className="fab fa-linkedin-in"></i>{" "}
                  </a>
                </li>
                <li>
                  <a href="https://www.twitter.com/4dstudiosUK"  target="_blank" rel="noreferrer">
                    {" "}
                    <i className="fab fa-twitter"></i>{" "}
                  </a>
                </li>
                <li>
                  <a href="https://facebook.com/4dcreativestudios/" target="_blank" rel="noreferrer">
                    {" "}
                    <i className="fab fa-facebook-f"></i>{" "}
                  </a>
                </li>
                <li>
                  <a href="https://dribbble.com/4dstudios" target="_blank" rel="noreferrer">
                    {" "}
                    <i className="fab fa-dribbble"></i>{" "}
                  </a>
                </li>

              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </>
);
