import React from "react";
import "../../scss/layouts/_our_clients.scss";
import Images from "../../assets";

export const WhatWe: React.FC<any> = () => (

    <section className="what_we-section">
        <div className="path_top"></div>
        <div className="path_bottom"></div>
        <div className="what_we-shape">
            <img src={Images.whatWeShape} alt="what_we-shape" />
        </div>
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="section__head">
                        <h3>What we do?</h3>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-3">
                    <div className="what__we__card">
                        <div className="what__we__card__icon">
                            <h3>W</h3>
                            <img src={Images.whatWe1} alt="what_we-1" />
                        </div>
                        <div className="what__we__card__title">
                            <h3>Web Development</h3>
                        </div>
                        <div className="what__we__card__desc">
                            <ul>
                                <li>Web Development </li>
                                <li>Progressive Web Apps </li>
                                <li>Custom Web Apps </li>
                                <li>CRM / CMS </li>
                                <li>Custom Programming </li>
                                <li>eCommerece Platform </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="what__we__card">
                        <div className="what__we__card__icon">
                            <h3>p</h3>
                            <img src={Images.whatWe2} alt="what_we-2" />
                        </div>
                        <div className="what__we__card__title">
                            <h3>Web Development</h3>
                        </div>
                        <div className="what__we__card__desc">
                            <ul>
                                <li>Product Design Sprint </li>
                                <li>Product Design </li>
                                <li>UX Review </li>
                                <li>Branding </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="what__we__card">
                        <div className="what__we__card__icon">
                            <h3>M</h3>
                            <img src={Images.whatWe3} alt="what_we-3" />
                        </div>
                        <div className="what__we__card__title">
                            <h3>Web Development</h3>
                        </div>
                        <div className="what__we__card__desc">
                            <ul>
                                <li>Android </li>
                                <li>iOS </li>
                                <li>React Native </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
);
