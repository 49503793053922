import React from "react";
import { Clients } from "./Clients";
import { GetTouch } from "./GetTouch";
import { Banner } from "./Banner";
import { Header } from "../Layout/Header";
import { Portfolio } from "./Portfolio";
import { Services } from "./Services";
const Main = () => {
  return (
    <>
      <Header />
      <Banner />
      <Services />
      <Portfolio />
      <Clients />
      <GetTouch />
    </>
  );
};

export default Main;
